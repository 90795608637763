<template>
  <b-modal
    :id="computedId"
    :size="size"
    :title="title"
    @hidden="$emit('hidden')"
    :hide-footer="hideFooter || !$slots.footer"
    :no-close-on-esc="noCloseOnEsc"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :header-class="{ 'no-padding': !title && !subTitle }"
    centered
  >
    <template #modal-header>
      <x-icon v-if="!hideXClose" class="close-icon" @click="cancel()" />
      <div v-if="title || subTitle" class="header-modal">
        <label class="title">{{ title }}</label>
        <br v-if="subTitle"/><label v-if="subTitle" class="subTitle">{{ subTitle }}</label>
      </div>
    </template>
    <template #modal-footer>
      <slot name="footer">{{  }}</slot>
    </template>
    <slot />
  </b-modal>
</template>

<script>
export default {
  data: () => ({}),

  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    hidden: {
      type: Function,
      default: () => false,
    },
    id: {
      type: String,
      default: null,
    },
    'hide-footer': {
      type: Boolean,
      default: false,
    },
    'no-close-on-esc': {
      type: Boolean,
      default: false,
    },
    'no-close-on-backdrop': {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    hideXClose: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.id || this._uid.toString();
    },
  },

  methods: {
    show() {
      this.$bvModal.show(this.computedId);
    },
    cancel() {
      this.$emit('cancel');
      this.$bvModal.hide(this.computedId);
    },
    hide() {
      this.$bvModal.hide(this.computedId);
    },
  },
};
</script>

<style>
.no-padding {
  padding: 0;
}

.modal-header .close-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 2rem;
  transform: translate(-100%, -50%);

  width: 32px;
  height: 32px;

  color: #00daae;
  background-color: #edfefa;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.modal-header {
  border-bottom: 0;
}

.header-modal .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
}

.header-modal .title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 27px;
}

.header-modal .subTitle {
  font-size: 0.875rem;
  color: #7E829F !important;
}

.modal-footer {
  border-top: 0;
}

.float-right {
  float: right;
}

.modal-content {
  padding: 20px;
  width: 100%;
}
</style>
