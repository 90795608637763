import Vue from 'vue';
import Vuex from 'vuex';

// eslint-disable-next-line import/no-cycle
import loginService from '@/services/login';
// eslint-disable-next-line import/no-cycle
import genericRequest from '@/services/genericRequest';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',
  state: {
    configuracao: {},
    metas: {},
    currentUnidade: null,
    genericData: {},
    multipleStepFormRequiresRelease: false,
    noSubmitOnEnter: false,
    hasDetectedMaterial: false,
    msgModalAuthentication: '',
    previousRoute: {},
    filtersStored: {},
    dashboardOptions: [],
    subSetores: [],
    inProcess: false,
    materiaisGenericosByTipo: null,
    materiaisGenericos: null,
    usuarioLogadoProcesso: {},
  },

  actions: {
    async getConfigs({ commit }) {
      const configs = await genericRequest.get({}, 'configuracao');
      const { unidades, ...restConfigs } = configs;

      commit('setConfiguracao', restConfigs);
      commit('setGenericData', { unidade: unidades });
    },

    async getMetas({ commit }) {
      const metas = await genericRequest.get({}, 'meta');
      commit('setMetas', metas);
    },

    async getDashboardOptionsFromApi({ commit, dispatch }) {
      const user = loginService.getUser();
      if (!user) {
        setTimeout(dispatch, 1000, 'getDashboardOptionsFromApi');
        return;
      }
      const dashboardOptions = await genericRequest.get({
        filters: {
          areas: user.areas,
          permissions: user.permissoes,
        },
      }, 'unidadeTipoDashboard');

      commit('setDashboardOptions', dashboardOptions);
    },

    async getMateriaisGenericosByTipo({ commit }) {
      const materiaisGenericosByTipo = await genericRequest.get({}, 'materialGenerico/allByTipo');

      commit('setMateriaisGenericosByTipo', materiaisGenericosByTipo);
    },

    async getCarrinhos({ commit }) {
      const carrinhos = await genericRequest.get({}, 'transporte/carrinho');

      commit('setCarrinhos', carrinhos);
    },

    async getHorariosVan({ commit }) {
      const horariosVan = await genericRequest.get({}, 'transporte/horarioVan');

      commit('setHorariosVan', horariosVan);
    },
  },

  getters: {
    currentUnidade: (state) => state.currentUnidade,
    configuracao: (state) => state.configuracao,
  },

  mutations: {
    setusuarioLogadoProcesso(state, value) {
      state.usuarioLogadoProcesso = value;
    },

    setInProcess(state, value) {
      state.inProcess = value;
    },

    setConfiguracao(state, config) {
      state.configuracao = config;
    },

    setMetas(state, metas) {
      state.metas = metas;
    },

    setDashboardOptions(state, dashboardOptions) {
      state.dashboardOptions = dashboardOptions;
    },

    setMetasUnidade(state, { idUnidade, metas }) {
      state.metas[idUnidade] = metas;
    },

    setUnidade(state, unidade) {
      state.currentUnidade = unidade;

      if (unidade) {
        unidade.tipoProcesso.sort((a, b) => a.id_tipo_processo - b.id_tipo_processo);
        const allSubSetores = [];
        const subSetores = unidade.tipoProcesso.reduce((acum, curr) => {
          let subSets = curr.processo.reduce((ac, proc) => {
            if (ac.find((item) => item.id_sub_setor === proc.fluxoSubSetor.id_sub_setor)) {
              return ac;
            }
            return [...ac, proc.fluxoSubSetor.subSetor];
          }, []);

          if (
            curr.tipoMaterialGenericoTipoProcesso
            && curr.tipoMaterialGenericoTipoProcesso[0]
            && curr.tipoMaterialGenericoTipoProcesso[0].id_sub_setor
          ) {
            subSets = curr.tipoMaterialGenericoTipoProcesso.reduce((acc, tmgTp) => {
              if (acc.find((item) => item.id_sub_setor === tmgTp.id_sub_setor)) {
                return acc;
              }
              return [...acc, { id_sub_setor: tmgTp.id_sub_setor, ...tmgTp.subSetor }];
            }, subSets);
          }

          const newAcum = [...acum];
          const { UnidadeTipoProcesso, processo, ...tipoProcessoData } = curr;

          const permissaoArea = loginService.verifyAreas(tipoProcessoData.area.map(
            (area) => area.chave,
          ));

          subSets.forEach((subSet) => {
            const prevSubSet = allSubSetores.find((item) => item.id_sub_setor === subSet.id_sub_setor);
            if (!prevSubSet) {
              allSubSetores.push({
                ...subSet,
                tipoProcesso: [tipoProcessoData],
              });
            } else {
              prevSubSet.tipoProcesso.push(tipoProcessoData);
            }
            return allSubSetores;
          });

          if (!permissaoArea) return acum;
          subSets.forEach((subSet) => {
            const prevSubSet = newAcum.find((item) => item.id_sub_setor === subSet.id_sub_setor);
            if (!prevSubSet) {
              newAcum.push({
                ...subSet,
                tipoProcesso: [tipoProcessoData],
              });
            } else {
              prevSubSet.tipoProcesso.push(tipoProcessoData);
            }
          });
          return newAcum;
        }, []);
        subSetores.sort((a, b) => a.id_sub_setor - b.id_sub_setor);
        state.subSetores = subSetores;
        state.allSubSetores = allSubSetores;
        state.genericData = { ...state.genericData, subSetor: subSetores };
        state.genericData = { ...state.genericData, allSubSetor: allSubSetores };
      }
    },

    setMultipleStepFormRequiresRelease(state) {
      state.multipleStepFormRequiresRelease = false;
    },

    setNoSubmitOnEnter(state, value) {
      state.noSubmitOnEnter = value;
    },

    setHasDetectedMaterial(state, value) {
      state.hasDetectedMaterial = value;
    },

    setMsgModalAuthentication(state, value) {
      state.msgModalAuthentication = value;
    },

    setGenericData(state, objRouteData) {
      state.genericData = {
        ...state.genericData,
        ...objRouteData,
      };
    },

    setPreviousRoute(state, previousRoute) {
      state.previousRoute = previousRoute;
    },

    setFiltersStored(state, newFilters) {
      state.filtersStored = newFilters;
    },

    setMateriaisGenericosByTipo(state, newMateriaisGenericosByTipo) {
      state.materiaisGenericosByTipo = newMateriaisGenericosByTipo;

      let materialGenerico = [];
      /* newMateriaisGenericosByTypo.reduce((acum, item) => {
        return [...acum, item.materialGenerico.map((el) => ({
          tipoMaterialGenerico: item.nome,
          ...el,
        }))];
      }, []); */
      newMateriaisGenericosByTipo.forEach((mat) => {
        mat.materialGenerico.forEach((el) => {
          el.tipoMaterialGenerico = mat.nome;
          materialGenerico = [...materialGenerico, el];
        });
      });
      materialGenerico.sort((a, b) => b.id_material_generico - a.id_material_generico);
      state.materiaisGenericos = materialGenerico;
    },

    setCarrinhos(state, newCarrinho) {
      state.genericData = { ...state.genericData, carrinho: newCarrinho };
    },

    setHorariosVan(state, newHorarioVan) {
      state.genericData = { ...state.genericData, horarioVan: newHorarioVan };
    },
  },
});

export default store;
