<template>
  <div>
    <b-row align-h="between" class="pt-3">
      <b-col cols="6">
        <b-button
          v-if="canClearFilters"
          @click="$emit('clear-filters')"
          variant="none"
          class="btn secondary-contained-button text-white align-self-center"
          :data-cy="`Adicionar ${name}`"
          >Apagar Filtros</b-button
        >
      </b-col>
      <b-col id="tool-bar" cols="6" class="text-right">
        <slot name="botoes">
          <b-button
            v-if="!noadd"
            @click="addRow()"
            variant="none"
            class="btn align-items-center secondary-contained-button text-white"
            :data-cy="`Adicionar ${name}`"
            ><PlusIcon width="20" height="20" class=" with-icon" /> Adicionar {{ name }}</b-button
          >
          <b-button
            v-if="!nofilters"
            @click="toggleFilters()"
            variant="none"
            class="btn toggle-button align-self-center"
            :data-cy="`Adicionar ${name}`"
            ><SlidersIcon v-b-tooltip.hover.top="'Filtrar'"
          /></b-button>
        </slot>
      </b-col>
    </b-row>
    <div :class="scroll ? 'table-responsive' : 'table-responsive-md'">
      <table class="table table-hover" data-cy="Tabela" :data-asc="sortAsc">
        <thead>
          <tr>
            <th scope="col" v-for="(col, idx) in colunas" :key="idx">
              {{ typeof col === 'string' ? col : col.value || '-' }}
            </th>
            <th v-if="fotografia" fotografia>Visualizar fotografias</th>
            <th v-if="historico" historico>Histórico</th>
            <th v-if="!noedit" data-editar>Editar</th>
            <th v-if="rastreabilidade" rastrear>Rastrear</th>
            <th v-if="reposicao" reposicao></th>
            <th v-if="deletados" deletados>Reativar</th>
            <th v-if="detalhesInventario" detalhesInventario></th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr v-for="i in 3" :key="i">
            <td
              class="p-1"
              v-for="j in noedit ? colunas.length : colunas.length + 3"
              :key="j"
            >
              <tb-skeleton
                shape="rect"
                style="background-color: #dcdcdc; height: 2rem; width: 100%"
                theme="opacity"
              ></tb-skeleton>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="sortedItems.length === 0">
          <tr>
            <td scope="row" class="text-center" :colspan="colunas.length + 2">
              {{ errMsg || '(Vazio)' }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(item, index) in sortedItems" :key="index">
            <th scope="row">
              <slot
                :name="
                  typeof colunas[0] === 'string'
                    ? colunas[0]
                    : colunas[0].value || ''
                "
                :value="item.cols[0]"
                :item="item"
              >
                {{ item.cols[0] }}
              </slot>
            </th>
            <td v-for="(coli, index) in item.cols.length - 1" :key="index">
              <slot
                :name="
                  typeof colunas[coli] === 'string'
                    ? colunas[coli]
                    : colunas[coli].value || ''
                "
                :value="item.cols[coli]"
                :item="item"
              >
                {{
                  item.cols[coli] || item.cols[coli] === 0
                    ? item.cols[coli]
                    : '-'
                }}
              </slot>
            </td>
            <td v-if="fotografia" fotografia class="align-middle">
              <PhotoView
                :options="{fotosItem: item.historicoHref.params.materialGenerico.fotoMaterialGenerico}"
                position="relative"
                margin="0 0 0 -1%"
              />
            </td>
            <td v-if="historico" historico class="align-middle">
              <router-link
                data-cy="historico"
                :to="item.historicoHref"
                class="btn btn-sm btn-outline-light text--black"
                title="Histórico"
              ><fileText-icon class="w20px"
              /></router-link>
            </td>
            <td v-if="!noedit" data-editar class="align-middle">
              <router-link
                data-cy="editar"
                :to="item.href"
                class="btn btn-sm btn-outline-light text--black"
                title="Editar"
                ><edit-icon class="w20px"
              /></router-link>
            </td>
            <td v-if="rastreabilidade" rastrear class="align-middle">
              <router-link
                data-cy="rastrear"
                :to="item.rastrearHref"
                class="btn btn-sm btn-outline-light text--black"
                title="Rastrear"
                ><truck-icon class="w20px"
              /></router-link>
            </td>
            <td v-if="reposicao" reposicao class="align-middle">
              <Button
                @click="updateQtdPecas(item.reposicaoHref)"
                type="outline"
                text="Repor"
              />
            </td>
            <td v-if="detalhes" detalhes class="align-middle">
              <Button
                @click="$emit('showDetalhes', item)"
                type="outline"
                text="Detalhes"
              />
            </td>
            <td v-if="deletados" deletados class="align-middle">              
              <b-button
                class="custom-print-button"
                variant="outline-dark"
                @click="showModal(item)"
              >
                <pocket-icon class="w20px" />
              </b-button>       
            </td>
            <td v-if="detalhesInventario" detalhesInventario class="align-middle">              
              <Button
                @click="showDetalhesInventario(item)"
                type="outline"
                text="Detalhes"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import removeAccents from 'remove-accents';
import Button from '@/components/Utils/Button';
import PhotoView from '@/components/Utils/PhotoView';
import { debounce } from '@/helpers/common';

const argToString = (str) => {
  if (str === null) return '-';
  return removeAccents(str.toString().toLowerCase());
};
const isQueryInString = (query) => (str) => argToString(str).includes(query);
const parseQuery = (query) => removeAccents(query.trim().toLowerCase());

export default {
  name: 'DataTable',
  components: {
    Button,
    PhotoView,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    colunas: {
      type: Array,
      required: true,
    },
    linhas: {
      type: Array,
      required: true,
    },
    errMsg: {
      // type: Object||String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Object,
      dafault: () => ({
        sortBy: 0,
        sortAsc: true,
        query: '',
      }),
    },
    nosearch: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    noedit: {
      type: Boolean,
      default: false,
    },
    nofilters: {
      type: Boolean,
      default: false,
    },
    noadd: {
      type: Boolean,
      default: false,
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    rastreabilidade: {
      type: Boolean,
      default: false,
    },
    reposicao: {
      type: Boolean,
      default: false,
    },
    detalhes: {
      type: Boolean,
      default: false,
    },
    historico: {
      type: Boolean,
      default: false,
    },
    fotografia: {
      type: Boolean,
      default: false,
    },
    canClearFilters: {
      type: Boolean,
      default: false,
    },
    deletados: {
      type: Boolean,
      default: false,
    }, 
    detalhesInventario: {
      type: Boolean,
      default: false,
    },    
  },
  data() {
    const iState = this.state
      ? { ...this.state }
      : {
        sortBy: 0,
        sortAsc: true,
        query: '',
      };
    return {
      iState,
      q: parseQuery(iState.query),
    };
  },
  computed: {
    sortBy: {
      get() {
        return this.iState.sortBy;
      },
      set(v) {
        this.iState.sortBy = v;
      },
    },
    sortAsc: {
      // 1 para ascendente ou -1 para descendente
      get() {
        return this.iState.sortAsc ? 1 : -1;
      },
      set(v) {
        this.iState.sortAsc = v === 1;
      },
    },
    query: {
      get() {
        return this.iState.query;
      },
      set(v) {
        this.iState.query = v;
      },
    },
    sortedItems() {
      let result = [];
      if (this.q === '') {
        result = this.linhas || [];
      } else {
        // escolhe linhas que alguma coluna tem a query
        result = this.linhas
          && this.linhas.filter((l) => l.cols.some(isQueryInString(this.q)));
      }

      if (this.hasPagination) return result;

      return result.sort((a, b) => {
        if (a.cols[this.sortBy] < b.cols[this.sortBy]) {
          return this.sortAsc * -1;
        }
        if (a.cols[this.sortBy] > b.cols[this.sortBy]) {
          return this.sortAsc * 1;
        }
        return 0;
      });
    },
  },
  created() {
    this.search = debounce(this.search, 500);
  },
  methods: {
    updateQtdPecas(router) {
      this.$router.push({ name: router.name, params: router.params });
    },
    search() {
      this.q = parseQuery(this.query);
      this.emitState();
    },
    emitState() {
      this.$emit('state-change', { ...this.iState });
    },
    updateState() {
      this.iState = { ...this.state };
    },
    addRow() {
      this.$emit('addRow');
    },
    toggleFilters() {
      this.$emit('toggleFilters');
    },
    openModalFotografia(v) {
      this.$emit('open-modal-fotografia', v.historicoHref.params);
    },
    showModal(v){
      this.$emit('reativa', v.reativaHref.params);
    },
    showDetalhesInventario(v){
      this.$emit('showDetalhesInventario', v)
    }
  },
  watch: {
    'state.query': 'updateState',
    'state.sortBy': 'updateState',
    'state.sortAsc': 'updateState',
  },
};
</script>

<style lang='scss' scoped>
$neutral-color-70: #5e627a;

.table-responsive {
  margin-bottom: 24px;
}

table thead th {
  font-weight: 500;
  color: #35384d;

  user-select: none;
  border: none;
}

table thead th[data-ordenar] {
  cursor: pointer;
  user-select: none;
}

table thead th[data-active] {
  color: #007bff;
}

table thead th[data-ordenar]::after {
  content: ' ↕';
  color: #66666680;
}

table[data-asc='1'] thead th[data-active]::after {
  content: ' ↑';
  color: inherit;
}

table[data-asc='-1'] thead th[data-active]::after {
  content: ' ↓';
  color: inherit;
}

thead th {
  white-space: nowrap;
  text-overflow: ellipsis;
  /*padding-top: 0;*/
}

tbody {
  color: #5e627a;
}

table tbody tr {
  border-radius: 10px;
}

tbody tr th {
  padding: 24px;
}

tbody tr td {
  padding: 24px 12px;
}

tbody tr {
  padding: 24px;
  margin-top: 12px;
  background-color: white;
  border-radius: 5px;
}

table {
  border-collapse: separate;
  border-spacing: 0 12px;
  margin-top: -12px;
}

.table th,
.table td {
  vertical-align: middle;
}

[data-editar] {
  width: 8rem;
  text-align: center;
}

[fotografia] {
  align-items: center;
  justify-content: center;
  display: flex;
}

[rastrear] {
  width: 8rem;
  text-align: center;
}

[imprimir-qr-code] {
  width: 8rem;
  text-align: center;
}
.container-pesquisa {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
}

.text--black {
  color: #35384d;
}

.form-control {
  height: calc(1.5em + 0.75rem + 4px);
  border: none;
}

.input-group-prepend {
  height: 40px;
}

.input-group-prepend .input-group-text {
  color: $neutral-color-70;
  background-color: white;
  border: 0px;
  border-radius: 0 5px 5px 0; /* n está aplicando a borda*/
}

#tool-bar {
  padding-bottom: 24px;
}

#tool-bar .btn {
  margin-left: 12px;
}

</style>
