<template>
  <nav
    class="
      navbar
      fixed-top
      pl-0
      d-flex
      justify-content-between
      align-items-center
    "
  >
    <div class="navbar-left d-flex align-items-center h-100">
      <div
        class="init py-1 d-flex justify-content-center align-items-center"
        :class="showBackButton ? 'mr-0 ' : 'mr-3'"
        ref="navbar-header"
      >
        <div
          class="
            toggle-menu
            p-3
            h-100
            d-flex
            justify-content-center
            align-items-center
            text-white
          "
          @click="menuButtonClicked"
        >
          <menu-icon v-b-tooltip.hover.right="'Esconder a navegação'" />
        </div>
        <div class="d-flex align-items-center spaced brand">
          <h1 class="mb-0 logo">InVision</h1>
          <div v-if="nomeFantasia" class="vertical-line mx-2"></div>
          <h1 v-if="nomeFantasia" class="mb-0 produto">{{ nomeFantasia }}</h1>
        </div>
      </div>
      <div
        class="
          go-back
          px-2
          mr-2
          d-flex
          justify-content-center
          align-items-center
        "
        @click="backButtonClicked"
        :class="{ 'force-hidden': !showBackButton }"
      >
        <arrow-left-icon />
      </div>
    </div>

    <div
      class="navbar-right d-flex align-items-center h-100 pr-2 py-1"
      v-if="showRightNavBar"
    >
      <Modal
        ref="modalRastrearMaterial"
        size="md"
        title="Rastrear Material"
        id="modalRastrearMaterial"
        @hidden="hiddenRastrearModal"
      >
        <div>
          <b-form-select
            id="dropdownTipoRastreio"
            name="selectTipoRastreio"
            v-model="selected"
            :options="choices"
          ></b-form-select>

          <!--Parte ID Material e QR Code-->
          <b-form-group v-if="selected == 'idOrCode'">
            <b-form-input
              @keyup.enter="goRastrear"
              id="material"
              ref="rastreioInput"
              placeholder="Digite aqui..."
              v-model="idRastreio"
            />
          </b-form-group>

          <!--Parte Lote e/ou ID Equipamento-->
          <b-form-group v-else>
            <div id="equipField">
              <label for="equipamento">Equipamento</label>
              <GenericSelect
                ref="selectEquipamento"
                name="equipamento"
                labelKey="nome"
                v-model="id_equipamento"
                route="equipamento"
                :customFilters="equipamentoFilters"
                :disabled="false"
                :state="validateState('equipamento')"
              ></GenericSelect>
            </div>
            <div id="loteField">
              <label for="lote">Lote</label>
              <b-form-input
                @keyup.enter="goRastrear"
                id="loteEsterilizacao"
                ref="rastreioInput"
                placeholder="Digite o lote..."
                v-model="idRastreio"
              />
            </div>
          </b-form-group>
        </div>
        <template #footer>
          <Button
            @click="goRastrear"
            class="primary-contained-button mt-1 float-right"
            text="BUSCAR"
            :loading="buscandoMaterial"
          />
        </template>
      </Modal>
      <div>
        <Button
          text="Rastrear Material"
          icon="truck"
          id="button-rastreabilidade-2"
          variant="primary-light"
          type="contained"
          :loading="false"
          :disabled="false"
          @click="openRastrearModal"
        />
      </div>

      <div
        class="align-items-center ml-3 dropdown-unidade-box"
        v-if="usuario && !redefinicao"
      >
        <b-nav-item-dropdown
          :disabled="!hasPermition('c_unidade') || listUnidades.length === 1"
          :no-caret="!hasPermition('c_unidade')"
        >
          <template #button-content>
            <label class="m-0">
              <p class="nomes" id="nomeUnidade">
                Unidade:&nbsp;{{ currentUnidadeName }}
              </p>
              <p id="nomesTextoUnidade" class="nomesTexto">Você está aqui</p>
            </label>
          </template>
          <div v-for="item in listUnidades" :key="item.nome">
            <b-dropdown-item
              @click="changeUnidade(item)"
            >{{ item.nome }}</b-dropdown-item>
          </div>
        </b-nav-item-dropdown>
      </div>

      <b-dropdown class="ml-2" variant="transparent">
        <template class="avatarButton" slot="button-content">
          <b-avatar></b-avatar>
        </template>

        <b-dropdown-item disabled>
          <div class="wrapper">
            <p class="name mb-0">
              <strong class="nomes" id="nomeUsuario">{{
                usuario || 'Desconectado'
              }}</strong>
            </p>
            <p id="nomesTextoUsuario" class="role mb-0 nomesTexto">
              {{ cargo || '-' }}
            </p>
          </div>
        </b-dropdown-item>

        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item class="logoutButton">
          <b-btn
            variant="light"
            :to="{ name: 'logout' }"
            class="log-in-out-btn"
            title="Sair"
            @click="clearUnidade"
            v-b-tooltip.hover.viewport
            ><log-out-icon />
            Sair da Conta
          </b-btn>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div
      class="w-100 py-3 collapse"
      :class="{ show: !collapsed }"
      id="navbarToggle"
      style=""
    >
      <div class="container d-flex justify-content-between align-items-center">
        <!-- // TODO - COLLAPSED -->
      </div>
    </div>
    <div
      class="more d-none justify-content-center align-items-center p-3"
      :class="{ collapsed: collapsed }"
      @click="toggleCollapsed"
      data-toggle="collapse"
      data-target="#navbarToggle"
      aria-controls="navbarToggle"
      :aria-expanded="!collapsed"
      aria-label="Mostrar mais"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill="dimgray"
          d="M14 12c0-0.269-0.054-0.528-0.152-0.765-0.102-0.246-0.25-0.465-0.434-0.649s-0.404-0.332-0.649
          -0.434c-0.237-0.098-0.496-0.152-0.765-0.152s-0.528 0.054-0.765 0.152c-0.246 0.102-0.465
          0.25-0.649 0.434s-0.332 0.404-0.434 0.649c-0.098 0.237-0.152 0.496-0.152 0.765s0.054
          0.528 0.152 0.765c0.102 0.246 0.25 0.465 0.434 0.649s0.404 0.332 0.649 0.434c0.237
          0.098 0.496 0.152 0.765 0.152s0.528-0.054 0.765-0.152c0.246-0.102 0.465-0.25
          0.649-0.434s0.332-0.404 0.434-0.649c0.098-0.237 0.152-0.496 0.152-0.765zM21
          12c0-0.269-0.054-0.528-0.152-0.765-0.102-0.246-0.25-0.465-0.434-0.649s-0.404-0.332
          -0.649-0.434c-0.237-0.098-0.496-0.152-0.765-0.152s-0.528 0.054-0.765 0.152c-0.246
          0.102-0.465 0.25-0.649 0.434s-0.332 0.404-0.434 0.649c-0.098 0.237-0.152 0.496-0.152
          0.765s0.054 0.528 0.152 0.765c0.102 0.246 0.25 0.465 0.434 0.649s0.404 0.332 0.649
          0.434c0.237 0.098 0.496 0.152 0.765 0.152s0.528-0.054 0.765-0.152c0.246-0.102
          0.465-0.25 0.649-0.434s0.332-0.404 0.434-0.649c0.098-0.237 0.152-0.496 0.152-0.765zM7
          12c0-0.269-0.054-0.528-0.152-0.765-0.102-0.246-0.25-0.465-0.434-0.649s-0.404-0.332
          -0.649-0.434c-0.237-0.098-0.496-0.152-0.765-0.152s-0.528 0.054-0.765 0.152c-0.246
          0.102-0.465 0.25-0.649 0.434s-0.332 0.404-0.434 0.649c-0.098 0.237-0.152 0.496-0.152
          0.765s0.054 0.528 0.152 0.765c0.102 0.246 0.25 0.465 0.434 0.649s0.404 0.332 0.649
          0.434c0.237 0.098 0.496 0.152 0.765 0.152s0.528-0.054 0.765-0.152c0.246-0.102
          0.465-0.25 0.649-0.434s0.332-0.404 0.434-0.649c0.098-0.237 0.152-0.496 0.152-0.765z"
        ></path>
      </svg>
    </div>
  </nav>
</template>

<script>
/**
 * ======================================================================================
 * Este módulo escuta eventos do root. São eles:
 * --------------------------------------------------------------------------------------
 * - loginChange
 * --------------------------------------------------------------------------------------
 * - navbar/tituloExtra
 *     Define um título adicional ao que está no arquivo de rotas.
 *     Esse título adicional é limpo sempre que a rota muda. Caso esse evento seja chamado
 * mais de uma vez, a texto dado na segunda chamada sobreescreverá o dado na primeira,
 * e assim por diante.
 *     Para emitir o evento, de (quase) qualquer lugar do código, chame
 *       this.$root.$emit('navbar/tituloExtra', 'meu título extra');
 * ======================================================================================
 */

import { mapState } from 'vuex';
import { debounce } from '@/helpers/common';

import login from '@/services/login';
import GenericApi from '@/services/genericRequest';
import Modal from '@/components/Utils/Modal';
import Button from '@/components/Utils/Button';
import GenericSelect from '@/components/Form/GenericSelect';
import unidadeService from '@/services/unidade';

const PLACEHOLDER_TITLE = 'InVision';

export default {
  components: {
    Modal,
    Button,
    GenericSelect,
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titulo: PLACEHOLDER_TITLE,
      maxWidthIntervalUpdates: 0,
      resFn: null,
      collapsed: true,
      usuario: '',
      cargo: '',
      redefinicao: false,
      tituloExtra: '',
      currentUnidade: null,

      buscandoMaterial: false,
      idRastreio: '',
      id_equipamento: null,

      selected: 'idOrCode',
      choices: [
        { value: 'idOrCode', text: 'ID ou QR Code' },
        { value: 'numLote', text: 'Num. lote e/ou equipamento' },
      ],
    };
  },
  methods: {
    hasPermition(permition) {
      return login.verifyPermissions([permition]);
    },
    showAlert(value) {
      if (this.inProcess) {
        swal({
          title: 'Alterar unidade',
          text: 'Deseja realmente alterar a unidade atual?',
          icon: 'warning',
          buttons: { cancel: 'Não', confirm: 'Sim' },
        }).then((v) => {
          if (v) {
            this.$store.commit('setInProcess', false);
            const unidade = this.unidades
              .find((item) => item.id_unidade === value.id_unidade);
            login.setUnidade(unidade);
            this.currentUnidade = login.getUnidade();
          }
        });
      }
    },
    changeUnidade(value) {
      if (!this.hasPermition('c_unidade')) return;
      if (this.inProcess) this.showAlert(value);
      else {
        const unidade = this.unidades
          .find((item) => item.id_unidade === value.id_unidade);
        login.setUnidade(unidade);
        this.currentUnidade = login.getUnidade();
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }

      return null;
    },
    openRastrearModal() {
      this.$refs.modalRastrearMaterial.show();
    },
    printSwal(tipo, text) {
      swal({
        title: `${tipo} não encontrado`,
        text,
        icon: 'warning',
      });
    },
    getTipo() {
      return this.$refs.rastreioInput.id;
    },
    async getIdMaterial() {
      try {
        const { id_material } = await GenericApi.getWithoutPagination(
          {}, `material/byId/${this.idRastreio}`,
        );

        return id_material;
      } catch (error) {
        let errorMessage = 'Não encontramos o material através do código informado. Verifique e tente novamente.';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        this.printSwal('Material', errorMessage);
        return false;
      }
    },
    getQuery() {
      let query = '';

      if (this.$refs.selectEquipamento.value != null && this.idRastreio) {
        query = `?numLote=${this.idRastreio}&equipamento=${this.$refs.selectEquipamento.value}`;
      } else if (this.$refs.selectEquipamento.value != null && !this.idRastreio) {
        query = `?equipamento=${this.$refs.selectEquipamento.value}`;
      } else query = `?numLote=${this.idRastreio}`;

      return query;
    },
    isEmpty() {
      return !((this.$refs.rastreioInput && this.$refs.rastreioInput.value)
        || (this.$refs.selectEquipamento && this.$refs.selectEquipamento.value));
    },
    async goRastrear() {
      this.buscandoMaterial = true;
      try {
        if (this.isEmpty()) {
          return;
        }

        const tipo = this.getTipo();

        if (tipo === 'material') {
          const id_material = await this.getIdMaterial();
          if (id_material) {
            this.$router.push(`/${tipo}/rastrear/${id_material}`);
          }
        } else if (tipo === 'loteEsterilizacao') {
          const query = this.getQuery();
          this.$router.push(`/${tipo}${query}`);
        }

        this.idRastreio = '';
        this.id_equipamento = null;

        this.$refs.modalRastrearMaterial.hide();
      } finally {
        this.buscandoMaterial = false;
      }
    },
    hiddenRastrearModal() {
      this.idRastreio = '';
    },
    menuButtonClicked() {
      this.$emit('menu-button-click');
    },
    backButtonClicked() {
      this.$emit('back-button-click');
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    updateTitle(newVal) {
      this.titulo = newVal || PLACEHOLDER_TITLE;
      this.tituloExtra = '';
    },
    setUser() {
      const user = login.getUser();
      this.usuario = user ? user.nome : '';
      this.redefinicao = user ? user.redefinicao : false;
      this.cargo = user ? user.grupo : '';
    },
    clearUnidade() {
      this.currentUnidade = null;
    },
  },

  watch: {
    '$route.meta.title': 'updateTitle',
  },
  mounted() {
    this.currentUnidade = login.getUnidade();
    this.updateTitle(this.$route.meta.title);
    this.resFn = debounce(() => {
      if (this.$refs['navbar-header']) {
        const hWidth = getComputedStyle(
          this.$refs['navbar-header'],
          null,
        ).getPropertyValue('width');
        this.$emit('change-max-width', parseFloat(hWidth.replace('px', '')));
      }
    }, 300).bind(this);

    // atualiza maxWidth ao redimensionar a janela do navegador
    window.addEventListener('resize', this.resFn);

    // atualiza maxWidth de tempos em tempo nos primeiros minutos por causa do carregamento de fontes
    const UPDATE_INTERVAL = 5000; // ms
    const MAX_INTERVAL_UPDATES = Math.ceil((1 * 60 * 1000) / UPDATE_INTERVAL); // 1 minuto

    (function updateMaxWidth() {
      this.resFn();
      if (++this.maxWidthIntervalUpdates < MAX_INTERVAL_UPDATES) {
        setTimeout(updateMaxWidth.bind(this), UPDATE_INTERVAL);
      }
    }.bind(this)());
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resFn);
  },
  created() {
    this.currentUnidade = null;
    this.setUser();
    this.$root.$on('loginChange', () => this.setUser());
    this.$root.$on('navbar/tituloExtra', (titulo) => {
      this.tituloExtra = titulo;
    });
  },
  computed: {
    showRightNavBar() {
      return this.usuario && this.currentUnidadeStore;
    },

    ...mapState({
      currentUnidadeStore: (state) => state.currentUnidade,
      nomeFantasia: (state) => state.configuracao && state.configuracao.nomeFantasia,
      equipamentoFilters: (state) => ({ id_unidade: state.currentUnidade.id_unidade }),
      unidades: (state) => state.genericData.unidade,
      inProcess: (state) => state.inProcess,
    }),

    currentUnidadeName() {
      if (this.currentUnidadeStore === null) {
        return 'Selecione a sua unidade';
      }
      return this.currentUnidadeStore.nome;
    },

    hideRastrearButton() {
      return this.selectedDashboard === 'cci';
    },

    listUnidades() {
      const unidades = unidadeService.getUnidadesUsuario();
      if (!this.currentUnidadeStore) return [];
      if (!unidades) return [];
      return unidades.filter((elem) => elem.id_unidade !== this.currentUnidadeStore.id_unidade);
    },
  },
};
</script>

<style scoped>
:root {
  --primary-color-10: #209f85;
  --neutral-color-10: #35384d;
}

.dropdown-unidade-box {
  list-style-type: none;
}

.force-hidden {
  display: none !important;
}

.spaced {
  padding-left: 30px;
  padding-right: 30px;
}

.linhas-btn {
  width: 48px;
  height: 43px;
  margin-top: -1px;
  margin-right: -4px;

  border-radius: 10px;
}

.vertical-separator {
  width: 0;
  height: 30px;
  border-right: 1px solid #ddd;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 768px) and (max-width: 1158px) {
  .container-fluid > .navbar-collapse,
  .container-fluid > .navbar-header {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .navbar-toggle {
    display: block !important;
  }

  .navbar > .container-fluid .navbar-brand {
    margin-left: 0px !important;
  }

  .navbar-nav {
    margin: 7.5px -15px !important;
    float: none !important;
  }

  .navbar-nav > li,
  .navbar-header {
    float: none !important;
  }

}

/* --- NAVBAR --- */
.navbar {
  background-color: white;
  color: var(--neutral-color-10);
  /* padding: .5rem 1rem; */
  padding: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

.navbar .init {
  background-color: white;
}

.navbar .toggle-menu {
  width: 70px;
  cursor: pointer;
  transition: 0.3s;
}

.navbar .toggle-menu svg {
  color: #35384d;
}

.navbar h1.logo,
.navbar h1.produto {
  color: var(--neutral-color-10);
}

.navbar h1.logo {
  /* height: 40px */
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 2em;
}

.navbar .produto {
  font-weight: 200;
  font-size: 2em;
}

.navbar .vertical-line {
  width: 3px;
  height: 25px;
  border-right: 2px solid #35384d;
}

.navbar .navbar-left {
  position: relative;
  height: 100%;
}

.navbar-icon {
  cursor: pointer;
  /* fill: dimgray */
}

.navbar .user {
  border-radius: 10px;
  transition: 0.3s;
}

.navbar .user .avatar {
  background-color: var(--primary-color-10);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 9px;
  border-radius: 10px;
}

.navbar .user .role {
  font-size: 0.8rem;
}

.navbar .more {
  transition: 0.3s;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 64px;
}

.navbar .more[aria-expanded='true'] {
  transform: rotate(90deg);
}

.navbar .nomes {
  color: #5e627a;
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.navbar #nomeUnidade {
  font-size: 15px;
}

.navbar #nomeUsuario {
  font-size: 18px;
}

.navbar .nomesTexto {
  color: #8d92b2;
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
}

.navbar #nomesTextoUnidade {
  font-size: 13px;
  text-align: right;
}

.navbar #nomesTextoUsuario {
  font-size: 16px;
}

#dropdownTipoRastreio, #equipField {
  margin-bottom: 5%;
}

@media (min-width: 791px) {
  .navbar .collapse {
    background-color: red !important;
    display: none !important;
  }
}

@media (max-width: 790px) {
  .navbar-right {
    display: none !important;
  }

  .navbar .more {
    display: flex !important;
  }

  .navbar .brand {
    display: none !important;
  }
}

.btn.log-in-out-btn {
  background-color: #fff;
  color: #7e829f;
  font-family: Poppins;
  font-weight: normal;
}

.red-text {
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
