/* eslint-disable max-len, global-require */
import store from '../store';
/**
 * Copia por referência os valores de um objeto para outro e retorna este outro
 * @param {any} source objeto de origem
 * @param {Array<String|Number>} params array de parâmetros a serem copiados. ex.: ['id', 'nome', 'idade', 0]
 *
 */
export const copyProps = (source) => (params) => params.reduce((acc, key) => ({
  ...acc,
  [key]: source[key],
}), {});

/**
 * Copia por valor um objeto (através dos métodos JSON stringify e parse)
 * @param {any} o object
 */
export const copy = (o) => JSON.parse(JSON.stringify(o));

/**
 * @callback objectFactory
 * @param {Array<String|Number>} params array de parâmetros a serem copiados. ex.: ['id', 'nome', 'idade', 0]
 */
/**
 * Copia por valor (JSON parse and stringify) os valores de um objeto para outro e retorna este outro
 * @param {any} source objeto de origem
 * @return {objectFactory}
 */
export const hardCopyProps = (source) => (params) => params.reduce((acc, key) => ({
  ...acc,
  [key]: copy(source[key]),
}), {});

/**
 * Acessa propriedade profundas dentro de um objeto com checagem de null
 * @param {any[]} p vetor de path. ex.: ['user', 0, 'comments']
 * @param {any} o object
 */
export const deep = (p) => (o) => p.reduce((acc, idx) => ((acc && !(acc[idx] == null)) ? acc[idx] : null), o);

/**
 * Acessa propriedade profundas dentro de um objeto com checagem de null, com _dot notation_
 * @param {String} p path separado por pontos . ex.: 'user.0.comments'
 */
export const ddeep = (p) => deep(p.split('.'));

/**
 * Retorna uma promessa que resolve após `ms` milissegundos. Internamente, essa função usa `setTimeout`.
 * @param {Number} ms milissegundos
 */
export const wait = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Função de debounce. Para utilizar o this do Vue dentro da função 'debounced', você DEVE fazer um bind para this
 * da seguinte forma: `debounce(function(){ ... }).bind(this)` ou `debounce(() => { ... }).bind(this)`.
 */
export const debounce = require('tiny-debounce');

export const TRY_TIMEOUT = 4000; // ms

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const firstUpperCase = (s) => {
  if (typeof s !== 'string') {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const buildPaginationFooter = (totalRows, rowsPerPage, currentPage) => {
  const str = 'Exibindo registros <strong>[initial]</strong> a <strong>[final]</strong> de um total de <strong>[total]</strong>';
  const total = new Intl.NumberFormat('pt-BR').format(totalRows);
  const initialRows = (rowsPerPage * currentPage - rowsPerPage) + 1;
  let finalRows = rowsPerPage * currentPage;
  finalRows = (finalRows > totalRows) ? totalRows : finalRows;

  return str
    .replace('[initial]', initialRows)
    .replace('[final]', finalRows)
    .replace('[total]', total);
};

export const convertToCsv = (data) => {
  const arrayOfObjs = data && data.length > 0 ? data : [{}];

  const keys = Object.keys(arrayOfObjs[0]);
  let str = `${String(keys)}\r\n`;
  arrayOfObjs.forEach((item) => {
    let line = '';
    keys.forEach((key) => {
      if (line !== '') line += ',';
      line += `"${item[key]}"`;
    });

    str = `${str}${line}\n`;
  });

  return str;
};

export const exportCSVFile = (arrayOfObjs, fileTitle) => {
  const csv = convertToCsv(arrayOfObjs);

  const exportedFilename = fileTitle ? `${fileTitle}.csv` : 'file.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const testPath = (path, test) => {
  const partsPath = path.split('/');
  const partsTest = test.split('/');

  if (partsPath.length !== partsTest.length) return false;

  const hasError = partsPath.some((el, index) => {
    if (el.charAt(0) === ':') return false;
    if (el === partsTest[index]) return false;
    return true;
  });
  if (hasError) return false;
  return true;
};

export const clearMaterialData = (material) => {
  const {
    shownId,
    id,
    camposMaterial,
    code,
    id_class_material,
    uuid,
    id_tipo_material,
    id_setor,
    id_medico,
    id_fornecedor,
    saida_setor,
    saida_voucher,
    saida_cme,
    aviso,
    id_lote_material,
    id_procedimento_atual,
    bloco,
    id_unidade_emprestimo,
    patrimonio,
    createdAt,
    updatedAt,
    deletedAt,
    id_item_avulso,
    kit_temporario,
    id_setor_atual,
    id_ultimo_ciclo,
    tipoMaterial,
    setor,
    unidadePatrimonio,
    unidadeAtual,
    itemAvulso,
    medico,
    fornecedor,
    procedimentoAtual,
    pertenceA,
    ...restMaterial
  } = material;

  if (store.getters.configuracao.composicaoCaixa === 'sim') {
    delete restMaterial.itens;
  }
  return restMaterial;
};
