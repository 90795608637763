import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import VueResource from 'vue-resource';
import VueHtmlToPaper from 'vue-html-to-paper';
import skeleton from 'tb-skeleton';
import {
  TablePlugin,
  TooltipPlugin,
  CalendarPlugin,
  ButtonPlugin,
  PaginationPlugin,
  SpinnerPlugin,
  FormInputPlugin,
  LayoutPlugin,
  FormSelectPlugin,
  FormGroupPlugin,
  FormCheckboxPlugin,
  OverlayPlugin,
  ListGroupPlugin,
  NavPlugin,
  AvatarPlugin,
  DropdownPlugin,
  CardPlugin,
  ModalPlugin,
  FormRadioPlugin,
  FormFilePlugin,
  InputGroupPlugin,
  FormTimepickerPlugin,
  FormDatepickerPlugin,
  ImagePlugin,
  FormPlugin,
  FormTagsPlugin,
  CollapsePlugin,
  AlertPlugin,
} from 'bootstrap-vue';

import VeeValidate from 'vee-validate';
import vSelect from 'vue-select';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'tb-skeleton/dist/skeleton.css';
import 'vue-select/dist/vue-select.css';

import App from './App';
import GenericTable from './components/Table/GenericTable';
import store from './store';
import iconsPlugin from './plugins/iconsPlugin';
import { router } from './router';

Vue.use(VueMeta);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
};

Vue.use(VueHtmlToPaper, options);
Vue.use(skeleton);

Vue.component('v-select', vSelect);
Vue.component('GenericTable', GenericTable);

Vue.use(iconsPlugin);

Vue.use(VueResource);
Vue.use(VueRouter);

// bootstrap-vue components
Vue.use(TablePlugin);
Vue.use(TooltipPlugin);
Vue.use(CalendarPlugin);
Vue.use(ButtonPlugin);
Vue.use(PaginationPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormInputPlugin);
Vue.use(LayoutPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(OverlayPlugin);
Vue.use(ListGroupPlugin);
Vue.use(NavPlugin);
Vue.use(AvatarPlugin);
Vue.use(DropdownPlugin);
Vue.use(CardPlugin);
Vue.use(ModalPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormFilePlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormTimepickerPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(ImagePlugin);
Vue.use(FormPlugin);
Vue.use(FormTagsPlugin);
Vue.use(CollapsePlugin);
Vue.use(AlertPlugin);

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
  validity: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  renderError(h, error) {
    return h('ERROR', {
      style: {
        color: 'red',
      },
    }, error.stack);
  },
  store,
}).$mount('#app');
