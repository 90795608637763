<template>
  <b-form-select
    :name="name"
    v-model="selectedOption"
    :options="options"
    :disabled="isBusy || disabled"
    class="invision-input"
    :state="state"
    @change="valueChanged"
  >
    <template slot="first">
      <option :disabled="disabledFirstOption" :value="firstOption ? firstOption.value : null">
        {{ firstOption ? firstOption.label : '-- Por favor, selecione uma opção --' }}
      </option>
    </template>
  </b-form-select>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GenericApi from '@/services/genericRequest';
import unidadeService from '@/services/unidade';

const routesWithCacheInOtherRoute = [
  'setor',
  'equipamento',
  'subSetor',
  'materialGenericoByTipo',
  'tipoManutencao',
  'tipoProcesso',
];

export default {
  name: 'GenericSelect',
  props: {
    name: {
      type: String,
      required: false,
    },
    // Default value of the input, also used for edition
    value: {
      type: [Number, String],
      default: null,
    },
    // Indicates if the field is disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    disabledFirstOption: {
      type: Boolean,
      default: false,
    },

    // Method used to retrieve the available oprions
    apiFunction: {
      type: String,
      required: false,
      default() {
        return 'getWithoutPagination';
      },
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    route: {
      type: String,
      required: true,
    },

    // Filters used on the api method
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    // Filters used on the api method
    customFilters: {
      type: Object,
      default() {
        return {};
      },
    },
    // Name of the attribute that contains the displayed label
    labelKey: {
      type: String,
      required: true,
    },
    firstOption: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      options: [],
      selectedOption: this.value,
      apiResponse: [],
    };
  },

  computed: {
    ...mapState({
      genericData: (state) => state.genericData,
    }),
  },

  mounted() {
    this.fillOptions();
  },

  watch: {
    customFilters(newV, oldV) {
      if (JSON.stringify(newV) !== JSON.stringify(oldV)) {
        if (this.selectedOption) {
          this.selectedOption = null;
          this.valueChanged();
        }
        this.fillOptions();
      }
    },

    value() {
      this.selectedOption = this.value;
    },
  },

  methods: {
    ...mapActions(['getCarrinhos'], ['getHorariosVan']),

    valueChanged() {
      if (!this.disabled) {
        this.$emit('input', this.selectedOption);
      }
    },

    async fillOptions() {
      this.options = [];
      this.isBusy = true;
      const { route, customFilters, genericData } = this;
      const hasCustomFilters = Object.keys(customFilters).length;
      let result;
      if (routesWithCacheInOtherRoute.includes(route)) {
        result = await this.getFromOtherRoute(route, customFilters);
      } else if (genericData[route] && !hasCustomFilters) {
        result = genericData[route];
      } else {
        result = await this.api[this.apiFunction]({ customFilters }, route);
        if (!hasCustomFilters) {
          this.$store.commit('setGenericData', { [route]: result });
        }
      }

      if (!result.length) return;
      this.apiResponse = result;

      const idKey = this.getIdKeyFromObject(result[0]);
      this.options = result.map((row) => ({
        value: row[idKey],
        text: row[this.labelKey],
      }));

      this.selectedOption = this.value;
      this.$emit('filled', this.selectedOption);
      if (this.options.length === 1 && this.disabledFirstOption) {
        this.selectedOption = this.options[0].value;
        this.valueChanged();
      }
      this.isBusy = false;
    },

    getIdKeyFromObject(object) {
      return Object.keys(object).find((key) => key.slice(0, 2) === 'id');
    },

    async getFromOtherRoute(route, customFilters) {
      if (route === 'tipoManutencao') {
        if (!customFilters.id_tipo_manutencao || !this.genericData.tipoManutencao) {
          const tiposManutencao = await this.api[this.apiFunction]({ customFilters }, route);
          if (customFilters.id_tipo_manutencao) {
            return tiposManutencao.filter((tm) => customFilters.id_tipo_manutencao.includes(tm.id_tipo_manutencao));
          }
          return tiposManutencao;
        }
        if (customFilters.id_tipo_manutencao) {
          return this.genericData.tipoManutencao.filter(
            (tm) => customFilters.id_tipo_manutencao.includes(tm.id_tipo_manutencao),
          );
        }
        return this.genericData.tipoManutencao;
      }
      if (route === 'setor') {
        if (!customFilters.id_unidade || !this.genericData.unidade) {
          return this.api[this.apiFunction]({ customFilters }, route);
        }
        const unidade = this.genericData.unidade.find((u) => u.id_unidade === customFilters.id_unidade);
        let setor = [...unidade.setor];

        if (customFilters.id_setor) {
          const idsSetores = !Array.isArray(customFilters.id_setor) ? [customFilters.id_setor] : customFilters.id_setor;
          setor = setor.filter((s) => idsSetores.includes(s.id_setor));
        }

        return setor.sort((a, b) => {
          if (a.nome.toUpperCase() < b.nome.toUpperCase()) return -1;
          return 1;
        });
      }
      if (route === 'equipamento') {
        if (!customFilters.id_tipo_esterilizacao || !customFilters.id_unidade || !this.genericData.tipoEsterilizacao) {
          return this.api[this.apiFunction]({ customFilters }, route);
        }
        const tipoEsterilizacao = this.genericData.tipoEsterilizacao.find(
          (te) => te.id_tipo_esterilizacao === customFilters.id_tipo_esterilizacao,
        );
        return tipoEsterilizacao.equipamentos.filter((equip) => equip.id_unidade === customFilters.id_unidade);
      }
      if (route === 'subSetor') {
        if (customFilters.id_unidade) {
          let unidade = await unidadeService.getUnidades();
          unidade = unidade.find((elem) => elem.id_unidade === customFilters.id_unidade);
          const subSetores = unidade.tipoProcesso.reduce((acum, curr) => {
            let subSets = curr.processo.reduce((ac, proc) => {
              if (ac.find((item) => item.id_sub_setor === proc.fluxoSubSetor.id_sub_setor)) {
                return ac;
              }
              return [...ac, proc.fluxoSubSetor.subSetor];
            }, []);
            if (
              curr.tipoMaterialGenericoTipoProcesso
              && curr.tipoMaterialGenericoTipoProcesso[0]
              && curr.tipoMaterialGenericoTipoProcesso[0].id_sub_setor
            ) {
              subSets = curr.tipoMaterialGenericoTipoProcesso.reduce((acc, tmgTp) => {
                if (acc.find((item) => item.id_sub_setor === tmgTp.id_sub_setor)) {
                  return acc;
                }
                return [...acc, { id_sub_setor: tmgTp.id_sub_setor, ...tmgTp.subSetor }];
              }, subSets);
            }
            const newAcum = [...acum];
            const { UnidadeTipoProcesso, processo, ...tipoProcessoData } = curr;
            subSets.forEach((subSet) => {
              const prevSubSet = newAcum.find((item) => item.id_sub_setor === subSet.id_sub_setor);
              if (!prevSubSet) {
                newAcum.push({
                  ...subSet,
                  tipoProcesso: [tipoProcessoData],
                });
              } else {
                prevSubSet.tipoProcesso.push(tipoProcessoData);
              }
            });
            return newAcum;
          }, []);
          subSetores.sort((a, b) => a.id_sub_setor - b.id_sub_setor);
          return [...subSetores];
        }
        // após alteração da unidade o genericData.subSetor já será atualizado pelo vuex.
        if (!this.genericData.allSubSetor && !this.genericData.subSetor) {
          return [];
        }
        if (this.genericData.allSubSetor) return [...this.genericData.allSubSetor];
        /* Para uso ter disponivel todos os subSetores no seletor de consulta
        de protocolos quando usuario não tiver permissões de todas as areas */
        return [...this.genericData.subSetor];
      }
      if (route === 'materialGenericoByTipo') {
        if (
          (customFilters && !customFilters.idTipoMaterialGenerico)
          || !this.genericData['materialGenerico/byTipoMaterialGenerico']
        ) {
          return [];
        }
        if (!this.genericData['materialGenerico/byTipoMaterialGenerico']) {
          return this.api[this.apiFunction]({ customFilters }, route);
        }
        const tipoMaterialGenerico = this.genericData['materialGenerico/byTipoMaterialGenerico'].find(
          (tm) => tm.id_tipo_material_generico === customFilters.idTipoMaterialGenerico,
        );
        return tipoMaterialGenerico.materialGenerico;
      }
      if (route === 'tipoProcesso') {
        let tiposProcessos;
        if (!this.genericData.tipoProcesso) {
          tiposProcessos = await this.api[this.apiFunction]({ customFilters }, route);
        } else {
          tiposProcessos = this.genericData.tipoProcesso;
        }
        if (customFilters.id_tipo_processo && customFilters.id_tipo_processo.length > 0) {
          return tiposProcessos.filter((tm) => customFilters.id_tipo_processo.includes(tm.id_tipo_processo));
        }
        return tiposProcessos;
      }
      return [];
    },

    getChaveById(id, varName) {
      const result = this.apiResponse.filter((elem) => elem[varName] === id);
      return result[0].chave;
    },
  },
};
</script>
