export default {
  idTipoMaterialUnidadeFromProcedimento: [2, 4],
  unidadeNameColor: {
    Contorno: '#21CCA9',
    'Santo Agostinho': '#F8C51A',
    'Betim-Contagem': '#3989BF',
    Rede: '#AFB4D6',
  },
  unidadeIdColor: {
    1: '#21CCA9',
    2: '#F8C51A',
    3: '#3989BF',
  },
  subSetorNameColor: {
    Expurgo: '#73E5CF',
    Preparo: '#B4D8F0',
    Esterilização: '#1B856F',
  }
};
