<template>
  <div class="d-flex justify-content-between align-items-center wrapper">
    <h1 class="padding align-self-center">{{ title }}</h1>
    <div>
      <slot name="botoes"/>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>
$neutral-color-70: #5E627A;
$neutral-color-80: #35384D;

main {
  transition: all ease 0.3s;
}

a.router-link-active {
  color: $neutral-color-70;
  text-decoration: none;
}
.wrapper {
  position: relative;
}
.padding {
  padding: 0;
  padding-top: 12px;
  padding-bottom: 26px;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
  color: $neutral-color-80;
}

</style>
