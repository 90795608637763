<template>
  <button
    type="button"
    class="custom-button"
    :class="`${size} ${variant}-${type} ${disabled ? 'disabled' : ''} ${buttonClass}`"
    @click="$emit('click')"
    :disabled="disabled || loading"
  >
    <component
      :is="`${icon}-icon`"
      v-if="icon && !loading"
      width="18px"
      class="align-self-center"
      :class="size === 'only-icon' ? 'only-content' : 'mr-3'"/>
    <span v-if="loading" class="align-self-center">Aguarde...</span>
    <span
      v-else
      class="align-self-center"
      :class="{
        'text-size-sm': textSizeSm
      }"
    >
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    text: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
    type: {
      type: String,
      default: 'contained',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textSizeSm: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #209f85;
$primary-light: #21CCA9;
$secondary: #FF7D29;
$danger: #E52E41;
$grayscale: #F0F1FC;

.custom-button {
  border-radius: 7px;
  color: #FFFFFF;
  border: none;
}
.disabled {
  opacity: 0.5;
  box-shadow: 0px 0px 0px !important;
}

.xs {
  font-size: 12px;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  min-width: 50px;
  height: 29px;
  padding: 0 12px 0 12px;
}
.sm {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 33px;
  padding: 0 16px 0 16px;
}
.md {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  min-width: 110px;
  height: 40px;
  padding: 0 16px 0 16px;
}
.lg {
  height: 60px;
}
.only-icon {
  width: 40px;
  height: 40px;
  padding: 0 12px 0 12px;
}
.md-double-line {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  min-width: 110px;
  height: 60px;
  padding: 0 16px 0 16px;
}

.primary-contained {
  background: $primary;
}
.primary-contained:hover {
  background: #1D917A;
  box-shadow: 0px 2px 3px rgba(29, 145, 122, 0.4);
}
.primary-contained:active,
.primary-contained:focus {
  background: #1B856F;
  outline: 0px;
}
.primary-outline {
  background: transparent;
  border: 1px solid #CFD1E6;
  box-sizing: border-box;
  color: $primary;
}
.primary-outline:hover {
  background: rgba(229, 254, 248, 0.3);
  color: #1B856F;
}
.primary-outline:active,
.primary-outline:focus {
  background: rgba(229, 254, 248, 0.69);
  color: #1B856F;
  border: 1px solid #1B856F;
  box-sizing: border-box;
  outline: 0px;
}
.primary-light-text {
  background: transparent;
  border: none;
  box-sizing: border-box;
  color: $primary;
}
.primary-light-text:hover {
  background: rgba(229, 254, 248, 0.3);
  color: #1B856F;
}
.primary-light-text:active,
.primary-light-text:focus {
  background: rgba(229, 254, 248, 0.69);
  color: #1B856F;
  outline: 0px;
}

.primary-light-contained {
  background: $primary-light;
}
.primary-light-contained:hover {
  background: #1FBF9E;
  box-shadow:  0px 2px 3px rgba(31, 191, 158, 0.4);
}
.primary-light-contained:active,
.primary-light-contained:focus {
  background: #1DB394;
  outline: 0px;
}
.primary-light-outline {
  background: transparent;
  border: 1px solid #CFD1E6;
  box-sizing: border-box;
  color: $primary-light;
}
.primary-light-outline:hover {
  background: rgba(229, 254, 248, 0.3);
  color: #1DB394;
}
.primary-light-outline:active,
.primary-light-outline:focus {
  background: rgba(229, 254, 248, 0.69);
  color: #1DB394;
  border: 1px solid #1DB394;
  box-sizing: border-box;
  outline: 0px;
}

.secondary-contained {
  background: $secondary;
}
.secondary-contained:hover {
  background: #F26E18;
  box-shadow: 0px 2px 3px rgba(242, 110, 24, 0.4);
}
.secondary-contained:active,
.secondary-contained:focus {
  background: #E56817;
  outline: 0px;
}
.secondary-outline {
  color: $secondary;
  background: transparent;
  border: 1px solid #CFD1E6;
  box-sizing: border-box;
}
.secondary-outline:hover {
  background: rgba(255, 125, 41, 0.05);
  color: #E56817;
}
.secondary-outline:active,
.secondary-outline:focus {
  color: #E56817;
  background: rgba(255, 125, 41, 0.1);
  border: 1px solid #E56817;
  outline: 0px;
}

.danger-contained {
  background: $danger;
}
.danger-contained:hover {
  background: #D92134;
  box-shadow: 0px 2px 3px rgba(217, 33, 52, 0.4);
}
.danger-contained:active
.danger-contained:focus {
  background: #CC1427;
  outline: 0px;
}
.danger-outline {
  color: $danger;

  background: transparent;
  border: 1px solid #CFD1E6;
  box-sizing: border-box;
}
.danger-outline:hover {
  background: rgba(255, 51, 72, 0.05);
  color: #CC1427;
}
.danger-outline:active,
.danger-outline:focus {
  color: #CC1427;
  background: rgba(255, 51, 72, 0.1);
  border: 1px solid #CC1427;
  outline: 0px;
}
.danger-text {
  color: $danger;
  text-transform: uppercase;

  background: transparent;
  border: none;
  box-sizing: border-box;
}
.danger-text:hover {
  color: #CC1427;
}
.danger-text:active,
.danger-text:focus {
  color: #CC1427;
  background: rgba(255, 51, 72, 0.1);
  outline: 0px;
}

.grayscale-contained {
  color: #5E627A;
  background: $grayscale;
}
.grayscale-contained:hover {
  background: #E6E8F2;
  box-shadow: 0px 2px 3px rgba(230, 232, 242, 0.4);
}
.grayscale-contained:active,
.grayscale-contained:focus {
  color: #35384D;
  background: #E6E8F2;
  outline: 0px;
}
.grayscale-outline {
  color: #5E627A;

  background: transparent;
  border: 1px solid #CFD1E6;
  box-sizing: border-box;
}
.grayscale-outline:hover {
  background: #F7F7FA;
  color: #35384D;
}
.grayscale-outline:active,
.grayscale-outline:focus {
  color: #35384D;
  background: #F0F1FC;
  border: 1px solid #35384D;
  outline: 0px;
}
.grayscale-text {
  color: #5E627A;

  background: transparent;
  border: none;
  box-sizing: border-box;
}
.grayscale-text:hover {
  background: #F7F7FA;
  color: #01856A;
}
.grayscale-text:active,
.grayscale-text:focus {
  color: #01856A;
  background: #F0F1FC;
  outline: 0px;
}
.only-content {
  margin-left: -0.1rem;
}
</style>
